h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0.5em 0;
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 12px;
}

p {
  font-size: 16px;
  line-height: 1.2;
}

ul,
ol {
  margin-left: 1.25em;
  padding: 0;
  line-height: 1;
}

strong {
  font-weight: bold;
}

/* Alignment Classes */
.left-align {
  text-align: left;
}
.center-align {
  text-align: center;
}
.right-align {
  text-align: right;
}
